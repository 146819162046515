export const constants = {
    data() {
        return {
            billStatus: {
                "Cancelled": "отменён",
                "New": "новый",
                "Paid": "оплачен",
                "Printed": "распечатан",
                "PartiallyPaid": "частично оплачен",
                "OnlinePaymentPending": "ожидает онлайн оплаты"
            },
            availableForPaymentBillStatuses: ["New", "Printed", "OnlinePaymentPending"],
            visibleForUserBillStatuses: ["Cancelled", "Paid", "OnlinePaymentPending"]
        }
    }
}