import Vue from 'vue'
import VueRouter from 'vue-router'
import PaymentInfo from '../components/PaymentInfo.vue'
import CartContentInfo from '../components/CartContentInfo.vue'
import SuccessfulPayment from '../components/InfoPages/SuccessfulPayment.vue'
import FailPayment from '../components/InfoPages/FailPayment.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/paymentInfo' },
  { path: '/paymentInfo', name: 'billSearch', component: PaymentInfo },
  {
    path: '/paymentInfo/:guid',
    name: 'paymentInfo',
    component: PaymentInfo,
    props: true
  },
  { path: '/cartContentInfo', name: 'cartSearch', component: CartContentInfo },
  {
    path: '/cartContentInfo/:guid',
    name: 'cartContentInfo',
    component: CartContentInfo,
    props: true
  },
  { path: '/SbrfSuccessful', name: 'successfulPayment', component: SuccessfulPayment },
  { path: '/SbrfFail', name: 'failPayment', component: FailPayment }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
