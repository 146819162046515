<template>
  <div>
    <money-format
      v-if="pricePerOne != originPricePerOne"
      class="text-caption text-bold"
      :value="pricePerOne * quantity"
      locale="ru-RU"
      currency-code="RUB"
    />
      
      <money-format
        class="discount-format"
        v-if="pricePerOne != originPricePerOne"
        :value="discountValue"
        :hide-subunits=true
        locale="ru-RU" 
        currency-code="RUB"
      />
      <money-format
        :class="originalPriceClass"
        :value="originPricePerOne * quantity"
        locale="ru-RU"
        currency-code="RUB"
      />
  </div>
</template>

<script>
export default {
  props: ["pricePerOne", "originPricePerOne", "quantity"],
  computed: {
    originalPriceClass() {
      return this.pricePerOne != this.originPricePerOne
        ? "text-caption text-strike old-price"
        : "text-caption text-bold";
    },
    discountValue() {
      return (
        this.pricePerOne * this.quantity -
        this.originPricePerOne * this.quantity
      );
    },
  },
};
</script>

<style scoped>
.discount-format {
  font-size: 0.7rem;
  color: red;
  font-weight: bold;
  margin-bottom: -5px;
  margin-left: 10px;
  margin-right: -10px;
}
.old-price {
    color: gray;
}
</style>