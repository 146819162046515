<template>
  <div v-if="isMA">
  </div>
  <div v-else>
    <img :src="`${publicPath}logo.png`">
  </div>
</template>

<script>

export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      result: navigator.userAgent,
      isMA: navigator.userAgent.indexOf('ma-') > -1
    };
  }
};
</script>