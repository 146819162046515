<template>
  <div class="text-right q-pa-sm text-h6">
    <div class="row justify-end" v-if="originalPrice != totalPrice">
      <div class="col-auto">Сумма:</div>
      <money-format
        class="col-4 text-bold"
        :value="originalPrice"
        locale="ru-RU"
        currency-code="RUB"
      />
    </div>
    <div class="row justify-end" v-if="originalPrice != totalPrice">
      <div class="col-auto">Скидка:</div>
      <money-format
        class="col-4 text-bold discount-format"
        :value="originalPrice - totalPrice"
        locale="ru-RU"
        currency-code="RUB"
      />
    </div>
    <div class="row justify-end">
      <div class="col-auto">Общая стоимость:</div>
      <money-format
        class="col-4 text-bold"
        :value="totalPrice"
        locale="ru-RU"
        currency-code="RUB"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["originalPrice", "totalPrice"],
};
</script>

<style scoped>
.discount-format {
  color: red;
}
.inline {
  display: inline-block;
}
</style>