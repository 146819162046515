<template>
  <div>
    <the-error-dialog :errorMessage="errorMessage" @handle-error="handleError"/>
    <q-linear-progress v-if="isLoading" indeterminate />
    <the-search-form 
      v-else-if="cart === null"
      label="Введите Ваш личный идентификатор"
      @submitGuid="routeToNewGuid"
    />

    <Cart v-else :items="cart.Items" :guid="guid"/>
  </div>
</template>

<script>
import Cart from "../components/Cart/Cart.vue";
import TheSearchForm from "../components/TheSearchForm.vue";
import axios from "axios";

export default {
  props: ["guid"],
  components: { Cart, TheSearchForm },
  data() {
    return {
      inputGuid: "",
      cart: null,
      errorMessage: "",
      isLoading: false,
    };
  },
  methods: {
    searchCartByGuid(newGuid) {
      this.isLoading = true;

      axios
        .get(process.env.VUE_APP_API_GETPAYABLEITEMS, {
          params: { buyerId: newGuid },
        })
        .then((response) => {
          console.log(response.data);

          if (!response.data) {
            this.showError("Список услуг по данному идентификатору не найден");
            this.$router.push({ name: "cartSearch" });
          } else this.cart = response.data;
        })
        .catch((error) => {
          console.log("Request error", error);
          this.showError("Ошибка при загрузке счёта");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    routeToNewGuid(newGuid) {
      this.$router.push({
        name: "cartContentInfo",
        params: { guid: newGuid },
      });
    },
    showError(msg) {
      this.errorMessage = msg;
    },
    handleError() {
      this.errorMessage = null;
    }
  },
  mounted() {
    if (this.guid) {
      this.searchCartByGuid(this.guid);
    }
  },
  watch: {
    guid() {
      if (this.guid) {
        this.searchCartByGuid(this.guid);
      } else this.cart = null;
    },
    isErrorDialogVisible() {
      console.log(this.isErrorDialogVisible);
    },
  },
};
</script>
