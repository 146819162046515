export const converters = {
    methods: {
        dateConverter(date) {
            return (new Date(date)).toLocaleString([], {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            });
        }
    }
}