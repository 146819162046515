<template>
  <q-dialog
    v-model="isErrorDialogVisible"
    persistent
    transition-show="scale"
    transition-hide="scale"
  >
    <q-card style="width: 300px">
      <q-card-section>
        <div class="text-h6">Ошибка</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        {{ error }}
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="OK" color="primary" v-close-popup />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: ["errorMessage"],
  data() {
    return {
      isErrorDialogVisible: false,
      error: null
    };
  },
  watch: {
      errorMessage() {
        if (this.errorMessage){
          this.error = this.errorMessage;
          this.isErrorDialogVisible = true;
        }
      },
      isErrorDialogVisible() {
        if (!this.isErrorDialogVisible){
          this.error = null;
          this.$emit("handle-error", null);
        }
      }
  }
};
</script>