<template>
  <tr>
    <td>
      <div class="text-body2">{{ billItem.Name }}</div>
      <div>{{ billItem.Description }}</div>
    </td>
    <td class="text-right">
      <div class="text-caption">
        {{ billItem.Quantity }} {{ billItem.MeasureUnit }}
      </div>
    </td>
    <td class="text-right">
      <price-label
        :originPricePerOne="billItem.OriginalPricePerOne"
        :pricePerOne="billItem.PricePerOne"
        :quantity="billItem.Quantity"
      ></price-label>
    </td>
  </tr>
</template>

<script>
export default {
  props: ["billItem"],
};
</script>

<style lang="sass" scoped>
div
  white-space: normal
</style>