<template>
  <div>
    <the-error-dialog :errorMessage="errorMessage" @handle-error="handleError"/>
    <q-linear-progress v-if="isLoading" indeterminate />
    <the-search-form
      v-else-if="bill === null"
      label="Введите идентификатор счёта"
      @submitGuid="routeToNewGuid"
    />

    <Bill v-else :bill="bill" :guid="guid"/>
  </div>
</template>

<script>
import Bill from "../components/Bill/Bill.vue";
import TheSearchForm from "../components/TheSearchForm.vue";
import axios from "axios";

export default {
  props: ["guid"],
  components: { Bill, TheSearchForm },
  data() {
    return {
      inputGuid: "",
      bill: null,
      errorMessage: "",
      isLoading: false,
      baseUrl: window.location.origin
    };
  },
  methods: {
    searchBillByGuid(newGuid) {
      this.isLoading = true;

      axios
        .get(process.env.VUE_APP_API_GETBILL, {
          params: { billId: newGuid },
        })
        .then((response) => {
          if (!response.data) {
            this.showError("Счёт по данному идентификатору не найден");
            this.$router.push({ name: "billSearch" });
          } 
          else this.bill = response.data.Bill;
        })
        .catch((error) => {
          console.log("Request error", error);
          this.showError("Ошибка при загрузке счёта");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    routeToNewGuid(newGuid) {
      this.$router.push({
        name: "paymentInfo",
        params: { guid: newGuid },
      });
    },
    showError(msg) {
      this.errorMessage = msg;
    },
    handleError() {
      this.errorMessage = null;
    }
  },
  mounted() {
    if (this.guid) {
      this.searchBillByGuid(this.guid);
    }
  },
  watch: {
    guid() {
      if (this.guid) {
        this.searchBillByGuid(this.guid);
      } else this.bill = null;
    },
  },
};
</script>