<template>
  <div class="q-pa-md">
    <q-form class="q-gutter-md" @submit="updateInput">
      <q-input
        class="q-ma-md"
        v-model="inputValue"
        square
        outlined
        :label="label"
      >
        <template v-slot:append>
          <q-icon
            v-if="inputValue !== ''"
            name="clear"
            class="cursor-pointer"
            @click="inputValue = ''"
          />
        </template>
      </q-input>
    </q-form>
  </div>
</template>

<script>
export default {
  props: ["label"],
  model: {
    event: "submitGuid",
  },
  methods: {
    updateInput() {
      this.$emit("submitGuid", this.inputValue);
    },
  },
  data() {
    return {
      inputValue: "",
    };
  },
};
</script>