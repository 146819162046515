<template>
  <base-layout>
    <the-error-dialog
      :errorMessage="errorMessage"
      @handle-error="handleError"
    />
    <q-linear-progress v-if="isLoading" indeterminate />

    <div>
      <q-card>
        <q-card-section>
          <logo />
          <div class="text-h5" v-if="itemsSource.length === 0">
            Неоплаченных услуг не найдено
          </div>
          <div v-else class="text-h5">Список услуг на оплату</div>
        </q-card-section>

        <q-table
          v-if="itemsSource.length !== 0"
          :data="itemsSource"
          :columns="columns"
          selection="multiple"
          :selected.sync="selected"
          row-key="id"
          wrap-cells
          virtual-scroll
          :pagination.sync="pagination"
          :rows-per-page-options="[0]"
        >
          <template v-slot:body-cell-name="props">
            <q-td :props="props">
              <div class="text-body2">{{ props.value }}</div>
              <div>{{ props.row.description }}</div>
            </q-td>
          </template>

          <template v-slot:body-cell-quantity="props">
            <q-td :props="props">
              <q-field outlined :dense="true">
                <template v-slot:control>
                  <div class="self-center text-right full-width no-outline">
                    {{ props.value }} {{ props.row.measureUnit }}
                  </div>
                </template>
              </q-field>
              <q-popup-edit
                v-model="props.row.quantity"
                title="Изменить количество"
                buttons
                @save="updateTotalPrice"
              >
                <q-input
                  type="number"
                  v-model="props.row.quantity"
                  dense
                  autofocus
                  min="1"
                  :max="props.row.maxQuantity"
                />
              </q-popup-edit>
            </q-td>
          </template>

          <template v-slot:body-cell-originalPricePerOne="props">
            <q-td :props="props">
              <price-label
                :originPricePerOne="props.row.originalPricePerOne"
                :pricePerOne="props.row.pricePerOne"
                :quantity="props.row.quantity"
              ></price-label>
            </q-td>
          </template>
        </q-table>

        <the-price-footer
          v-if="itemsSource.length !== 0"
          :originalPrice="selectedOriginalPrice"
          :totalPrice="selectedItemsPrice"
        ></the-price-footer>

        <div class="row">
          <div class="q-ma-sm col" v-if="selectedItemsPrice > 0">
            <q-btn
              color="primary"
              class="full-width"
              label="Оплатить выбранные услуги"
              @click="paySelectedItems"
            />
          </div>
        </div>
      </q-card>
    </div>
  </base-layout>
</template>

<script>
import axios from "axios";
import PriceLabel from "../PriceLabel.vue";

export default {
  components: { PriceLabel },
  props: ["items", "guid"],
  data() {
    return {
      isLoading: false,
      errorMessage: "",
      selectedOriginalPrice: 0,
      selectedItemsPrice: 0,
      itemsSource: [],
      selected: [],
      columns: [
        {
          name: "name",
          label: "Наименование",
          align: "left",
          field: (row) => row.name,
          format: (val) => `${val}`,
          sortable: true,
        },
        {
          name: "quantity",
          align: "center",
          label: "Кол-во",
          field: "quantity",
          sortable: true,
        },
        {
          name: "originalPricePerOne",
          label: "Цена",
          field: "originalPricePerOne",
          sortable: true,
        },
      ],
      data: [],
    };
  },
  watch: {
    selected() {
      this.updateTotalPrice();
    },
    items() {
      this.updateItemSource();
    },
  },
  mounted() {
    this.updateItemSource();
  },
  methods: {
    updateItemSource() {
      this.itemsSource = [];
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        this.itemsSource.push({
          id: item.Id,
          itemType: item.ItemType,
          name: item.Name,
          quantity: item.Quantity,
          maxQuantity: item.Quantity,
          measureUnit: item.MeasureUnit,
          description: item.Description,
          originalPricePerOne: item.OriginalPricePerOne,
          totalPrice: item.TotalPrice,
          pricePerOne: item.PricePerOne,
        });
      }
      this.selected = this.itemsSource;
    },

    updateTotalPrice() {
      this.selectedItemsPrice = 0;
      this.selectedOriginalPrice = 0;
      this.selected.forEach((item) => {
        this.selectedItemsPrice += item.quantity * item.pricePerOne;
        this.selectedOriginalPrice += item.quantity * item.originalPricePerOne;
      });
    },

    paySelectedItems() {
      this.isLoading = true;

      const data = this.serializeSelectedItems();
      console.log(data);

      axios
        .post(process.env.VUE_APP_API_CREATEBILL, data)
        .then((response) => {
          console.log(response.data);

          if (!response.data || !response.data.BillId) {
            this.showError("Не удалось создать счёт. Попробуйте ещё раз позже");
          } else this.routeToNewBill(response.data.BillId);
        })
        .catch((error) => {
          console.log("Request error", error);
          this.showError("Ошибка при загрузке счёта");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    serializeSelectedItems() {
      const selectedItems = [];
      this.selected.forEach((item) => {
        selectedItems.push({
          id: item.id,
          itemType: item.itemType,
          quantity: item.quantity,
        });
      });
      const data = JSON.stringify({
        buyerId: this.guid,
        items: selectedItems,
      });
      return data;
    },

    routeToNewBill(billGuid) {
      this.$router.push({
        name: "paymentInfo",
        params: { guid: billGuid },
      });
    },

    showError(msg) {
      this.errorMessage = msg;
    },
    handleError() {
      this.errorMessage = null;
    },
  },
};
</script>

<style lang="sass" scoped>
.my-card
  width: 80%
.inline
  display: inline-block
.scroll
  overflow: hidden
</style>
