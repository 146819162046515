import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './quasar'
import MoneyFormat from 'vue-money-format'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Logo from "./components/Logo.vue"
import BaseLayout from "./components/BaseLayout.vue"
import TheErrorDialog from "./components/TheErrorDialog.vue"
import ThePriceFooter from "./components/ThePriceFooter.vue"
import PriceLabel from "./components/PriceLabel.vue"
import { converters } from "./mixins/converters"
import { constants } from "./mixins/constants"
import '../public/css/theme.css'

Vue.component('logo', Logo);
Vue.component('base-layout', BaseLayout);
Vue.component('the-error-dialog', TheErrorDialog);
Vue.component('money-format', MoneyFormat);
Vue.component('price-label', PriceLabel);
Vue.component('the-price-footer', ThePriceFooter);
Vue.use(VueAxios, axios);

if (process.env.NODE_ENV === 'development')
  axios.defaults.baseURL = process.env.VUE_APP_API_BASEURL;
else
  axios.defaults.baseURL = window.location.origin;
axios.defaults.headers.common['accept'] = 'application/json';
axios.defaults.headers['Content-Type'] = 'application/json';

Vue.mixin(constants);
Vue.mixin(converters);

Vue.config.productionTip = false;
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
