<template>
  <div class="full-width row justify-center" v-bind:class="{'verba-root': isVerba}">
    <div class="q-pa-md col-xs col-sm-8 col-md-6 col-lg-4 col-xl-3">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { Dark } from 'quasar'
Dark.set(navigator.userAgent.indexOf('ma-') > -1)

export default {
  data() {
    return {
      isVerba: navigator.userAgent.indexOf('ma-verba') > -1
    };
  }
};
</script>