import Vue from 'vue'

import './styles/quasar.sass'
import lang from 'quasar/lang/ru.js'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import Quasar from 'quasar/src/vue-plugin.js';import QInput from 'quasar/src/components/input/QInput.js';import QPageContainer from 'quasar/src/components/page/QPageContainer.js';import QPage from 'quasar/src/components/page/QPage.js';import QCard from 'quasar/src/components/card/QCard.js';import QCardSection from 'quasar/src/components/card/QCardSection.js';import QCardActions from 'quasar/src/components/card/QCardActions.js';import QDialog from 'quasar/src/components/dialog/QDialog.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';import QTable from 'quasar/src/components/table/QTable.js';import QTh from 'quasar/src/components/table/QTh.js';import QTr from 'quasar/src/components/table/QTr.js';import QTd from 'quasar/src/components/table/QTd.js';import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';import QBadge from 'quasar/src/components/badge/QBadge.js';import QField from 'quasar/src/components/field/QField.js';

Vue.use(Quasar, {
  components: {
    QInput,
    QPageContainer,
    QPage,
    QCard,
    QCardSection,
    QCardActions,
    QDialog,
    QTable,
    QTh,
    QTr,
    QTd,
    QLinearProgress,
    QBadge,
    QField
  },
  config: {
    dark: 'auto'
  },
  plugins: {
  },
  directives: {
    ClosePopup
  },
  lang: lang
})